import React, { useEffect, useState } from 'react'
import {BrowserRouter,Routes,Route, Link} from 'react-router-dom'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import About from './pages/About'
import Home from './pages/Home'
import OurCustomers from './pages/OurCustomers'
import Products from './pages/Products'
import Caraousel from './components/Caraousel'
import './components/Style.css'

import Atta from './pages/products/Atta'
import Maida from './pages/products/Maida'
import Rawa from './pages/products/Rawa'
import Sujji from './pages/products/Sujji'

export default function App() {
  // useEffect(() => {
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  // }, []);
  return <>


  <BrowserRouter>
  <Navigation/>
  

   <Routes>
     <Route path="/" element={<Home/>}></Route>
    <Route path="/about" element={<About/>}></Route>
    <Route path="/ourcustomers" element={<OurCustomers/>}></Route>
    <Route path="/products" element={<Products />}></Route> 
{/* products detail start*/}
    <Route path="/atta" element={<Atta/>}></Route>
    <Route path="/maida" element={<Maida/>}></Route>
    <Route path="/rawa" element={<Rawa/>}></Route>
    <Route path="/sujji" element={<Sujji/>}></Route>
    {/* products detail end*/}


  </Routes>
  <Footer/>
  <button
        onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}
        style={{
          position: 'fixed',
          // padding: '1rem 2rem',
          fontSize: '20px',
          bottom: '45px',
          right: '120px',
          backgroundColor: '#000',
          color: '#fff',
          textAlign: 'center',
        }}
      >
         <i class="bi bi-arrow-up "></i>

      </button>
  </BrowserRouter>
  </>
}
