import React from 'react'
import {motion} from 'framer-motion'

export default function Transitions({children}) {
    const animationConfiguration = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };
  return <>
  <motion.div
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 3 }}
        >
            {children}
        </motion.div>
  </>
}
