import React from 'react'
import home1 from '../pages/mrunali-images/home1'
import home2 from '../pages/mrunali-images/home2'
import home3 from '../pages/mrunali-images/home3'
import home4 from '../pages/mrunali-images/home4'

export default function Caraousel() {
  return <>

    <div className="container carousel-container ">
        <div class="m-0 p-0 bg-transparent">
          <div class="card-body" >
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
       </div>
      
       <div class="carousel-inner">
       <div className="col-md-6 col-sm-6 col-xs-6  mt-1 " >
         <div class="carousel-item active">
          
         <img src={home1} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg w-60 " role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         
     <img src={home2} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg w-60"  role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         <img src={home3} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg w-60" role="img" aria-label="Placeholder: third slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption  "></div>
         </div>
         <div class="carousel-item">
         <img src={home4} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg w-60"  role="img" aria-label="Placeholder: fourth slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption ">
           </div>
         </div>
       </div>
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" ></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          </div>
        </div>
 
  </div> 
  </>
}

