import React from 'react'
import Caraousel from '../components/Caraousel'
import Transitions from '../components/Transitions'
import customers1 from '../pages/mrunali-images/customers1'

export default function OurCustomers() {
  return <>
  <Transitions>
    <img src={customers1} alt=""  className="our-customer-img" />
  <div className="container">
    <div className="row">
  <h1 className='about m-5'> Customers Reviews</h1>
  <h5 className='customer-heading m-4 mt-0'>We strive to do best for our customers, after all you are the reason we are here. </h5>
   
   <div className="col-lg-4 col-md-3 col-xs-6">
    <div class="card">
      <div class="card-header customer-heading text-center">Rakesh Singh</div>
      <div class="card-body">
        <h3 className='about-detail mt-0'>Rakesh Singh:- </h3>
        <h6>This brand is my family’s favourite brand. They don’t prefer any other brand as we regularly eat guddi gold’s rotis. Give it a try, you’ll eat it and repeat it. </h6>
      </div>
    </div>
   </div>
   <div className="col-lg-4 col-md-3 col-xs-6">
    <div class="card">
      <div class="card-header customer-heading text-center"> Pallavi Sharma</div>
      <div class="card-body">
        <h3 className='about-detail mt-0'>Pallavi Sharma:- </h3>
        <h6>This brand is my family’s favourite brand. They don’t prefer any other brand as we regularly eat guddi gold’s rotis. Give it a try, you’ll eat it and repeat it. </h6>
      </div>
    </div>
   </div>
   <div className="col-lg-4 col-md-3 col-xs-6">
    <div class="card">
      <div class="card-header customer-heading text-center">Aarya bakshi </div>
      <div class="card-body">
        <h3 className='about-detail mt-0'>Aarya bakshi :- </h3>
        <h6>They have the best maida I would say. If you want to add more nutritious value to your meals, prefer guddi gold. Its best in taste and best for your family.  </h6>
      </div>
    </div>
   </div>
    </div>
    <div className="row mt-3 m-5">
    <div className="col-lg-4 col-md-3 col-xs-6">
    <div class="card">
      <div class="card-header customer-heading text-center">Rohan sen</div>
      <div class="card-body">
        <h3 className='about-detail mt-0'>Rohan sen:- </h3>
        <h6 className='customer-heading'>BEST AND HEALTHIER…ALWAYS PREFER GUDDI GOLD…TO MAKE YOUR TASTE BETTER… </h6>
      </div>
    </div>
   </div>
   <div className="col-lg-4 col-md-3 col-xs-6">
    <div class="card">
      <div class="card-header customer-heading text-center">Grishma das:</div>
      <div class="card-body">
        <h3 className='about-detail mt-0'>Grishma das:- </h3>
        <h6>I love how they always think about their customers, I really like its taste, they give us best in quality and I must appreciate they also give best in quantity in a reasonable rate.  </h6>
      </div>
    </div>
   </div>
    </div>
  </div>
  </Transitions>
  <Caraousel/>
  </>
}
