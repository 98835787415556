import React from 'react'
import home4 from "../mrunali-images/home4"
import atta1 from '../mrunali-images/product-images/atta1'
import AttaLogo from '../mrunali-images/AttaLogo'
import pooriBg from '../mrunali-images/pooriBg'
import Attadetail1 from '../mrunali-images/Attadetail1'
import Transitions from '../../components/Transitions'

// carousel images
import atta1New from '../mrunali-images/new-images/atta1New'
import atta2 from '../mrunali-images/new-images/atta2'
import atta3 from '../mrunali-images/new-images/atta3'
import AttaBg from '../mrunali-images/product-images/AttaBg'

export default function Atta() {
  return <>
  
  {/* <Transitions> */}
   <img src={AttaBg} alt="" className='bg-img-size1'/>
  <div className="rawa-bg">
  <div className="container">
    <div className="row">

    <div className="col-lg-4 col-md-6 col-xs-6  m-4">
<div className="box box-rawa">
<div className="container carousel-container ">
        <div class="m-0 p-0 bg-transparent "  >
          <div class="card-body  " >
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
         {/* <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button> */}
       </div>
      
       <div class="carousel-inner box">
       <div className="col-lg-6 col-md-4 col-sm-6   mt-1 " >
         <div class="carousel-item active ">
          
         <img src={home4} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg mt-0 w-60 atta-slider" role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         {/* <div class="carousel-item">
         
     <img src={atta1New} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60" width="400" height="400" role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div> */}
         <div class="carousel-item">
         <img src={atta1New} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-90 atta-slider"  role="img" aria-label="Placeholder: third slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption  "></div>
         </div>
         <div class="carousel-item">
         <img src={atta3} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"  role="img" aria-label="Placeholder: fourth slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption ">
           </div>
         </div>
       </div>
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" ></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          </div>
        </div>
 
  </div> 
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-xs-6 mx-4 ">
      <h1 className='about'>GUDDI GOLD ATTA: -</h1>
      <p className='about-detail'>Guddi gold atta by sanjay uttam agro foods is a
       whole wheat atta which has much more nutrients than just a wheat atta product. This atta comes with richness and goodness of nutritious elements. We as manufacturers keep our priority as the best quality as this wheat is scrutinized by our experts to give the best product our customers need. As wheat flour is a staple food of India, we provide you the gluten free version of premium atta which makes your meals more delicious and healthy. </p>

      <br />
    <h2 className='contact w-50 p-2'>HAS GOOD SOURCES OF:- </h2>
    <ul className='about-detail mt-4' >
      <li>Complex carbohydrates.</li>
      <li>Vitamin B1,B3,B5,riboflavin and folate.</li>
      <li>Protein</li>
      <li>Rich source of fibre and</li>
      <li>Minerals.</li>
    </ul>
<br />
<h2 className='contact w-50 p-2'>ADVANTAGES:- </h2>
    <ul className='about-detail mt-4'>
<li>Aids digestion</li>
<li>Cleanses the system</li>  
<li>Prevents weight gain</li>
<li>Improves mental health</li>
<li>Lowers the risk of heart diseases. </li>
   
    </ul>
<br />
<h2 className='contact w-50 p-2'>ADVANTAGES:- </h2>
    <ul className='about-detail mt-4'>
<li>Improves heart rate.</li>
<li>It boosts your energy.</li>  
<li>It helps to strengthen your immunity.</li>
<li>Easy bowel movement and  </li>
<li>Gives you healthy and strong muscle.</li>
    </ul>
    </div>
    </div>
  </div>
  </div>
  {/* </Transitions> */}
  </>
}
