import React from 'react'
import halwaBg from '../mrunali-images/halwaBg';
import home1 from '../mrunali-images/home1' 
import Transitions from '../../components/Transitions'

import sujiBgNew1 from '../mrunali-images/new-images/sujiBgNew1'
import suji2 from '../mrunali-images/new-images/suji2'
import suji3 from '../mrunali-images/new-images/suji3'

export default function Sujji() {
  return <>
  <Transitions>
  <div className="suji suji-bg">
  <img src={halwaBg} alt="" className='bg-img-size-suji'/>
  <div className="container">
    <div className="row">

    <div className="col-lg-4 col-md-6 col-xs-6  m-4">
<div className="box box-suji">
{/* <img src={home1} alt="Aata" height={600} width={700} className="bg-transparent"/> */}
<div className="container carousel-container ">
        <div class="m-0 p-0 bg-transparent "  >
          <div class="card-body " >
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
       </div>
      
       <div class="carousel-inner box">
       <div className="col-lg-6 col-md-4 col-sm-6   mt-1 " >
         <div class="carousel-item active ">
          
         <img src={home1} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg mt-0 w-60 atta-slider" role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         
     <img src={suji2} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"  role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         <img src={sujiBgNew1} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"  role="img" aria-label="Placeholder: third slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption  "></div>
         </div>
         <div class="carousel-item">
         <img src={suji3} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"   role="img" aria-label="Placeholder: fourth slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
                <div class="carousel-caption ">
           </div>
         </div>
       </div>
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" ></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          </div>
        </div>
 
  </div> 
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-xs-6 mx-3 ">
      <h1 className='about '>GUDDI GOLD SUJI: -</h1>
      <p className='about-detail'>Guddi gold suji is made from best quality coarse flour, whose natural taste and nutritious value are maintained through our excellent methods and milling. Our suji is one of our best products which can be used to make wide range of recipes and dishes to add an extra flavor to your daily meals by preserving your nutritious and healthy value. Add a little more healthiness and delicious taste to your kitchen with our best and fresh suji. </p>
   <br />
    <h2 className='contact w-50 p-2'>HAS GOOD SOURCES OF:- </h2>
    <ul className='about-detail mt-4' >
      <li>POTASSIUM.</li>
      <li>MAGNESIUM</li>
      <li>VITAMIN B</li>
      <li>CALCIUM etc.</li>
    </ul>
<br />
<h2 className='contact w-50 p-2'>ADVANTAGES:- </h2>
    <ul className='about-detail mt-4'>
<li>Controls blood sugar levels.</li>
<li>Boosts immunity.</li>  
<li>Controls cholesterol</li>
<li>Improves digestion and. </li>
<li>Helps in management of weight.</li>
    </ul>
    </div>
    </div>
  </div>
  </div>
  </Transitions>
  </>
}
