import React from 'react'
import maida1 from '../mrunali-images/product-images/maida1'
import maidaBg_2 from '../mrunali-images/product-images/maidaBg_2'
import home2 from '../mrunali-images/home2'
import Transitions from '../../components/Transitions'

import maidaNew1 from '../mrunali-images/new-images/maidaNew1'


export default function Maida() {
  return <>
  {/* <Transitions> */}

  <img src={maidaBg_2} alt="" className='bg-img-size1'/>
  <div className="maida-bg">
  <div className="container ">
    <div className="row ">
    <div className="col-lg-4  col-md-6 col-xs-6  m-0">
      <div className="box  box-maida m-0 ">

<div className="container carousel-container ">
        <div class="m-0 p-0 bg-transparent "  >
          <div class="card-body  " >
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
       </div>
      
       <div class="carousel-inner box">
       <div className="col-lg-6 col-md-4 col-sm-6   mt-1" >
         <div class="carousel-item active ">
          
         <img src={home2} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider mt-2" role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         
     <img src={maidaNew1} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider my-5" role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div>
         
       </div>
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon"></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          </div>
        </div>
 
  </div> 
</div>
    </div>
    <div className="col-lg-6 col-md-6 col-xs-6 mx-3 ">
      <h1 className='about'>GUDDI GOLD MAIDA: -</h1>
      <p className='about-detail mt-4'>Finely milled from whole wheat, guddi gold Maida is an all purpose flour for making a variety of dishes that would give you a taste which would make you go ‘wow’. We provide you Maida which has high gluten, iron and vitamin to add an extra point to your dish. Get ready to make a delicious pastry with Guddi gold Maida that would make you eat again and again.</p>
   <br />
    <h2 className='contact w-50 p-2'>HAS GOOD SOURCES OF:- </h2>
    <h4 className='about-detail mt-5'>WE MAKE OUR MAIDA FROM WHEAT WHICH CONTAINS vitamins, iron, magnesium, phosphorus , manganese and selenium. 
</h4>
<h2 className='about-detail '> <strong>  Make your bakery products with our ‘guddi gold’ maida to get a complete finished product. This multi-purpose flour will give you a best taste and more fine results. Our maida is finely milled, refined and bleached. </strong>
 </h2>
  
    </div>
    </div>
  </div>
  </div>


  {/* </Transitions> */}
  </>
}
