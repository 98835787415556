import React from 'react'
import  '../components/Style.css';
import {Link, Route, Routes} from 'react-router-dom'
import logo from "../pages/mrunali-images/logo"
import instagram from "../pages/mrunali-images/social-media/instagram"
import facebook from "../pages/mrunali-images/social-media/facebook"
import whatsup from "../pages/mrunali-images/social-media/whatsup"
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import About from '../pages/About';
import OurCustomers from '../pages/OurCustomers';
import Products from '../pages/Products';
import Atta from '../pages/products/Atta';
import Maida from '../pages/products/Maida';
import Rawa from '../pages/products/Rawa';
import Sujji from '../pages/products/Sujji';
import Home from '../pages/Home';

export default function Navigation() {
  return <>
  <nav class="navbar navbar-custom  navbar-expand-lg  ">
    <div class="container-fluid  ">
      <Link class="navbar-brand navbar-custom" to="/">
        <img src={logo} alt="" class="rounded-full " height={50} width={50}/>
        Guddi Gold</Link>
      <button class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collasible-nav-dropdown"
       aria-controls="collasible-nav-dropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navbar-manage navbar-collapse-custom" id="collasible-nav-dropdown">

        <ul class="navbar-nav  navbar-custom navbar-manage ">

        <li class="nav-item"><Link class="nav-link  navbar-custom" to="/">Home</Link></li>
        <li class="nav-item"><Link class="nav-link  navbar-custom" to="/about">About</Link></li>
        <li class="nav-item dropdown"><Link class="nav-link dropdown-toggle navbar-custom" to="/products" >Products
          <i class="bi bi-chevron-double-down mx-1" id="collasible-nav-dropdown"  role="button" data-bs-toggle="dropdown" aria-expanded="false"     type="button" ></i>
         
          <ul class="dropdown-menu text-white bg-danger" style={{color:"white"}}>
            <li><Link class="dropdown-item nav-link" to="/atta">Atta</Link></li>
            <li><Link class="dropdown-item nav-link" to="/maida">Maida </Link></li>
            <li><Link class="dropdown-item nav-link" to="/rawa">Rawa </Link></li>
            <li><Link class="dropdown-item nav-link" to="/sujji">Suji </Link></li>
          </ul>
       
          </Link></li>
         
          <ul class="navbar-nav  sm-icons navbar-social-menu ">
<li>
    <a  className="nav-social-link"href="https://instagram.com/guddi_gold?r=nametag"><img src={instagram} alt="" height={35} width={35} className="product-heading  m-1 bg-transparent" />
      </a></li>
   <li><a className="nav-social-link" href="https://www.facebook.com/MrunaliGuddiGold"><img src={facebook} alt="" height={35} width={35} className="product-heading  m-1 bg-transparent" />
  </a></li>
   {/* <li><a href="https://wa.me/9371611174?text=" className=" nav-social-link" target="blank"><img src={whatsup} alt="" height={35} width={35} className=" product-heading   bg-transparent" />
   </a></li> */}
   <li><a href="https://wa.me/9371611174?text=" className="float nav-social-link" target="blank"><img src={whatsup} alt="" height={35} width={35} className="my-float product-heading   bg-transparent" />
   </a></li>
   <div className="sol-sm-6 col-xs-6">
   <li><a href="https://wa.me/9371611174?text=" className="float nav-social-link" target="blank"><img src={whatsup} alt="" height={35} width={35} className="my-float product-heading   bg-transparent" />
   </a></li>
   </div>
      </ul>
        </ul>
      </div>
     
    </div>

      </nav>


   
  </>
}
