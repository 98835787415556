import React from 'react'
import Caraousel from '../components/Caraousel'
import CarouselAll from '../components/CarouselAll'
import productBgNew2  from '../pages/mrunali-images/product-images/productBgNew2'
import productBgNew  from '../pages/mrunali-images/product-images/productBgNew'
import productBg_4  from '../pages/mrunali-images/product-images/productBg_4'
import productBg_5 from '../pages/mrunali-images/product-images/productBg_5'
import product_3  from '../pages/mrunali-images/new-images/product_3'
import Transitions from '../components/Transitions'

export default function Products() {
  return <>
  {/* <Transitions> */}
 
  <div className="product" name="top" href="#top">
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
       </div>
      
       <div class="carousel-inner">
       
         <div class="carousel-item active">
          
         <img src={productBg_4} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg  bg-img-product" role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         <div class="carousel-item">
         
     <img src={productBg_5} alt="home1" class="carousel-img bd-placeholder-img bd-placeholder-img-lg  bg-img-product"  role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div>
         
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" ></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          

  <div className="product m-5">
  <h1 className='about mt-5'>GUDDI GOLD</h1>
  <h2 className='product-heading'> SWAAD EK BEHTAR KAL KA</h2>
  <p className='about-detail'>As we know India is well known for its good heritage, culture and food. And rotis are the part of staple diet of India. But, to make your rotis fluffy and soft you need best atta to get puffed-up fresh roti. Nowadays, there are tremendous amount of brands that you get confused about which brand you should prefer. We feel the brand and quality of atta you use also determines the taste and texture of those chapattis. For that you need to choose the brand which is really good and rich in protein that will keep your family together, happy and healthy. That’s why we are here to lower your confusion and help your family get together.   We at guddi gold by sanjay uttam agro food pvt. Ltd. provide you products that would add an extra nutrition value to your daily meals. We provide products that are 1)Atta 2) Maida 3)Rava 4)Suji
</p>
<br />
<p className='about-detail m-5 my-4'>And these are available in following quantities:-
</p>
<div className="container w-100">
  <div className="row">
    <div className="col-lg-6 col-md-6 col-xs-6">
    <table class="table table-dark table-striped table-hover">
    <tbody>
    <tr>
      <th scope="row">Atta</th>
      <td>0.5kg</td>
      <td>5kg</td>
      <td>10kg</td>
      <td>50kg</td>
    </tr>
    <tr>
      <th scope="row">RAVA</th>
      <td>0.5kg</td>
      <td>5kg</td>
      <td>10kg</td>
      <td>50kg</td>
    </tr>
    <tr>
    <th scope="row">Maida</th>

    <td>0.5kg</td>
      <td>5kg</td>
      <td>10kg</td>
      <td>15kg</td>
    </tr>
    <tr>
    <th scope="row">SUJI</th>

    <td>0.5kg</td>
      <td>5kg</td>
      <td>7kg</td>
      <td>10kg</td>
    </tr>
  </tbody>
</table>
    </div>
    <div className="col-lg-6 col-md-6 col-xs-6">
      <ul className='about-detail mt-3 m-5 '>
        <li>MADE WITH FINEST WHEAT.</li>
        <li className="mt-2">100% ATTA,0% MAIDA</li>
        <li className="mt-2">HYGIENIC AND SAFEST.</li>
        <li className="mt-2">PREEMINENT PACKAGING.</li>
      </ul>
    </div>
  </div>

</div>
  </div>
  <div className="product-text">
    <h1 className='about-detail text-center my-5 m-5'>We sell wholesale products like Rawa, Atta, Maida and Suji. To make your grocery buying easier and convienient 😊
</h1>
    <h1 className='about text-center'>Our Products</h1>

  </div>
<CarouselAll/>
</div>
{/* </Transitions> */}
  </>
}
