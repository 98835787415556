import React from 'react'
import Caraousel from '../components/Caraousel'
import home1 from '../pages/mrunali-images/home1'
import home2 from '../pages/mrunali-images/home2'
import home3 from '../pages/mrunali-images/home3'
import home4 from '../pages/mrunali-images/home4'
import home from '../pages/mrunali-images/home'
import atta2 from '../pages/mrunali-images/atta2'
import chakki from '../pages/mrunali-images/chakki'
import homebg from '../pages/mrunali-images/homebg'
import sujiDetail from '../pages/mrunali-images/sujiDetail'
import rawaDetail from '../pages/mrunali-images/rawaDetail'
import maidaDetail from '../pages/mrunali-images/maidaDetail'
import check from "../pages/mrunali-images/about-images/check"
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import Transitions from '../components/Transitions'


export default function Home() {
  return <>
{/* page animation start */}
{/* <Transitions> */}

<div className="container-fluid container-home">
  <div className="row bg-img-size">
<div className="col-lg-12 col-md-12 col-sm-6 col-xs-6"></div>
<img src={home} alt="" className="bg-img-size m-0 mt-0" />
<div className="centered mt-3">
<Caraousel/>
</div>
</div>
</div>

<div className="row home-bg">
<div className="col-md-6 col-sm-6 col-xs-4 block mt-1">
  <img src={homebg} alt=""  className="home-page-img mt-0 m-0"/>
</div>
<div className="col-md-5 col-sm-6 col-xs-6 m-3">
<h2 className='about mt-1 m-3 mb-0 '>
<TypeAnimation
      sequence={[
        'Guddi Gold', // Types 'One'
        1000, // Waits 1s
        'SWAAD EK BEHTAR KAL KA', // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        'About Us', // Types 'Three' without deleting 'Two'
        () => {
          // console.log('Done typing!'); // Place optional callbacks anywhere in the array
        }
      ]}
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      style={{ fontSize: '1em' }}
    />
  </h2>
<h6 className='customer-heading mt-1 m-3 '> Guddi Gold – Popular Atta & Flour Brand</h6>
<table class="table table-transparent  ">
    <tbody className='home-text about-detail' >
        <tr>
      <td>
        <img src={check} alt="" className="check-img" />
      </td>
      <td>MADE WITH FINEST WHEAT.</td>
      <td>
        <img src={check} alt="" className="check-img" />
      </td>
      <td>100% ATTA,0% MAIDA </td></tr>
    <tr>
    </tr>
    <tr>
      <td >
        <img src={check} alt="" className="check-img"/>
      </td>
      <td>HYGIENIC AND SAFEST.</td>
      <td>
        <img src={check} alt="" className="check-img"/>
      </td>
      <td>PREEMINENT PACKAGING. </td></tr>
    <tr>
    </tr>
  </tbody>
</table>
    <p className='about-detail home-text mt-4 animate-design'>Guddi Gold by Sanjay Uttam Agro foods pvt. Ltd. is one of the India’s agro brand that has diverse range of best quality products which includes rawa, maida, atta and suji. We understand how quality and taste matters the most to the healthy kitchens around the nation. To make your meals tastier and healthier, we present to you the finest quality of flours with natural nutrients and delightful packaged product. We provide you a healthier product with complete hygiene, love and care. </p>
<Link to="/about"> <button type="button" class="btn btn-outline-danger mt-3">Read More...</button></Link>
</div>
</div>

{/* Atta start */}
<div className="row home-bg ">
  <div className="col-md-5 col-sm-4 col-xs-6 mt-4 m-2 rounded-full">
    <h1 className='about ' >
    <img src={chakki} alt="" className='chakkiLogo' /> Guddi Gold Atta</h1>
 <p className='about-detail mt-2 animate-design home-para-text'>Guddi gold atta by sanjay uttam agro foods is a whole wheat atta which has much more nutrients than just a wheat atta product. This atta comes with richness and goodness of nutritious elements.</p>
  <Link to="/atta"><button type="button" class="btn btn-outline-danger mx-5">Read More...</button>
  </Link>
  </div>
<div className=" col-md-4 col-sm-4 col-xs-6 mt-0 m-0 mx-5 block  home-img">
  <img src={atta2} alt="" className="home-page-img1 home-img m-2"/>
</div>
</div>



{/* maida start */}
<div className="row  home-bg ">
  <div className="block col-md-6 col-sm-4 col-xs-6 m-2 my-0">
  <img src={maidaDetail} alt=""  className="home-page-img1 block home-img  mt-0 mx-3 "/>
</div>
<div className="col-md-5 col-sm-4 col-xs-6 mt-4 m-5">
    <h1 className='about' >
    <img src={chakki} alt="" className="bg-transparent chakkiLogo"/> Guddi Gold Maida</h1>
 <p className='about-detail mt-2 home-para-text home-maida animate-design'> Finely milled from whole wheat, guddi gold Maida is an all purpose flour for making a variety of dishes that would give you a taste which would make you go ‘wow’. We provide you Maida which has high gluten, iron and vitamin to add an extra point to your dish. </p>
  <Link to="/maida"><button type="button" class="btn btn-outline-danger mx-5">Read More...</button>
  </Link>
  </div>
</div>




{/* suji start */}
<div className="row home-bg">
  <div className="col-md-5 col-sm-4 col-xs-6 mt-4 m-2 rounded-full">
    <h1 className='about' >
    <img src={chakki} alt=""className='chakkiLogo' /> Guddi Gold Suji</h1>
 <p className='about-detail mt-2 home-para-text animate-design'> Guddi gold suji is made from best quality coarse flour, whose natural taste and nutritious value are maintained through our excellent methods and milling.</p>
  <Link to="/sujji"><button type="button" class="btn btn-outline-danger mx-5">Read More...</button>
  </Link>
  </div>
<div className=" col-md-4 col-sm-4 col-xs-6 block mt-0 m-0">
  <img src={sujiDetail} alt=""  className="home-page-img1 home-img mx-4 m-2"/>
</div>
</div>

{/* rawa start */}
<div className="row home-bg">
  <div className=" col-md-6 col-sm-4 col-xs-6 block my-0 m-2">
  <img src={rawaDetail} alt=""  className="home-page-img1 mx-3 home-img mt-0 "/>
</div>
<div className="col-md-5 col-sm-4 col-xs-6 mt-4 m-5 ">
    <h1 className='about ' >
    <img src={chakki} alt=""className="bg-transparent chakkiLogo"/> Guddi Gold Rawa</h1>
 <p className='about-detail mt-2home-para-text home-maida '>  Guddi gold rava is made from the best quality coarse rava, which is finely milled to give you the finest granular consistency. </p>
  <Link to="/rawa"><button type="button" class="btn btn-outline-danger mx-5">Read More...</button>
  </Link>
  </div>
</div>



<div className="home-text">
  <h4 className='about-detail text-center my-5 mx-5 animate-design'>
  Eat healthy and think better with this flour. Mrunali Guddi Gold introduces your favourite wheat flour menu powered by Sanjay Uttam Agro Foods Pvt.Ltd. 
    Introducing all of our flour menu. For amazing quality, good taste and reasonable prices visit your nearest grocery store and try our flour menu now! It’s tasty and nutritious with a lot of health benefits.
</h4>
</div>
{/* </Transitions> */}
            {/* page animation end */}
      {/* <h1 className='about text-center'>Our Products</h1> */}
  {/* <Caraousel/> */}
  

  
  </>
}
