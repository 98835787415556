import React from 'react'
import rawaBg from '../mrunali-images/rawaBg';
import home3 from '../mrunali-images/home3'
import Transitions from '../../components/Transitions'

import rawa2 from '../mrunali-images/new-images/rawa2'
import rawa3 from '../mrunali-images/new-images/rawa3'


export default function Rawa() {
  return <>
  <Transitions>
   <img src={rawaBg} alt="" className='bg-img-size-rawa'/>
  <div className="rawa-bg">
  <div className="container">
    <div className="row">

    <div className="col-lg-4 col-md-6 col-xs-6  m-4">
<div className="box box-rawa">
{/* <img src={home3} alt="Aata" height={600} width={700} className="bg-transparent"/> */}
<div className="container carousel-container ">
        <div class="m-0 p-0 bg-transparent "  >
          <div class="card-body  " >
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
       
       <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
         {/* <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button> */}
       </div>
      
       <div class="carousel-inner box">
       <div className="col-lg-6 col-md-4 col-sm-6   mt-1 " >
         <div class="carousel-item active ">
          
         <img src={home3} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg mt-0 w-60 atta-slider" role="img" aria-label="Placeholder: first slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class="carousel-caption ">
           </div>
         </div>
         {/* <div class="carousel-item">
         
     <img src={atta1New} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60" width="400" height="400" role="img" aria-label="Placeholder: second slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
           <div class=" carousel-caption ">
           </div>
         </div> */}
         <div class="carousel-item">
         <img src={rawa2} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"  role="img" aria-label="Placeholder: third slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption  "></div>
         </div>
         <div class="carousel-item">
         <img src={rawa3} alt="home1" class="bd-placeholder-img bd-placeholder-img-lg w-60 atta-slider"   role="img" aria-label="Placeholder: fourth slide" preserveAspectRatio="xMidYMid slice" focusable="false" />
     
           <div class="carousel-caption ">
           </div>
         </div>
       </div>
       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" ></span>
         <span class="visually-hidden">Previous</span>
       </button>
       <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
         <span class="carousel-control-next-icon" ></span>
         <span class="visually-hidden">Next</span>
       </button>
     </div>
     </div>
          </div>
        </div>
 
  </div> 
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-xs-6 mx-3 ">
      <h1 className='about'>GUDDI GOLD RAWA: -</h1>
      <p className='about-detail'>Guddi gold rava is made from the best quality coarse rava, which is finely milled to give you the finest granular consistency. It can be used to make a wide range of dishes with its delicious taste and nutritious elements. It will make your family unite at the dining table by its mesmerizing smell and delicious taste. </p>

      <p className='about-detail'>Our rava is so finely made and it is filtered to keep it hygienic and clean so that our customers are not disappointed and can make a wide variety of dishes and share happiness and the best “ Rave ka laddoo” to bring some laughter into your homes. 
</p>
   <br />
    <h2 className='contact w-50 p-2'>HAS GOOD SOURCES OF:- </h2>
    <ul className='about-detail mt-4' >
      <li>IRON.</li>
      <li>PROTEIN</li>
      <li>CALCIUM .</li>
    </ul>
<br />
<h2 className='contact w-50 p-2'>ADVANTAGES:- </h2>
    <ul className='about-detail mt-4'>
<li>Improves heart rate.</li>
<li>It boosts your energy.</li>  
<li>It helps to strengthen your immunity.</li>
<li>Easy bowel movement and  </li>
<li>Gives you healthy and strong muscle.</li>
    </ul>
    </div>
    </div>
  </div>
  </div>
  </Transitions>
  </>
}
