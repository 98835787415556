import React from 'react'
import { Link } from 'react-router-dom'
import '../components/Style.css'

export default function Footer() {
  return <>
   {/* footer */}

   <div className="footer mt-5">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-6 col-xs-12 ">
  <h5 class="footer-title text-white mt-4">ABOUT US</h5>
      <p className='footer-heading text-white '>Guddi Gold by Sanjay Uttam Agro foods pvt. Ltd. is one of the India’s agro brand 
  that has diverse range of best quality products which includes rawa, maida, atta and suji. We understand how quality
   and taste matters the most to the healthy kitchens around the nation. To make your meals tastier and healthier, we present to you the finest quality of flours with natural nutrients and delightful packaged product.  </p>
      </div>
      <div className="col-lg-4 col-md-6 col-xs-12" >
        <h3 class="footer-title mt-4 text-center mr-2">Products</h3>
        <ul className='m-3 mx-5 '>
          <Link className='nav-link' to="/atta"><ol className='text-white m-2 " '>Guddi Gold Atta </ol></Link>
          <Link className='nav-link' to="/maida"><ol className='text-white m-2 " '>Guddi Gold Maida</ol></Link>
          <Link className='nav-link' to="/rawa"><ol className='text-white m-2 " '>Guddi Gold Rawa </ol></Link>
          <Link className='nav-link' to="/sujji"><ol className='text-white m-2 " '>Guddi Gold Suji </ol></Link>
        </ul>

    <div className="row about-btn">
        <div className="col-lg-6  col-xs-6 about-btn ">
       <Link to="/ourcustomers"><button type="button"  class="btn btn-outline-dark  "> Customers Reviews</button></Link> </div>
     
    </div>
              </div>
      <div className="col-lg-4 col-md-6 col-xs-12">
      <h1 className='footer-title mt-4'>Contact Us</h1>

<input type="text" className='form-control'  placeholder='name' />
<br />
<input type="text" className='form-control' placeholder='Email Address' />
<br />
<textarea className='form-control' placeholder='Message'  rows="5"></textarea>
<br />

<button type="button" class="btn btn-dark mx-4 text-center my-3">Submit</button>

      </div>
      
    </div>
   
  </div>
    </div>
  </>
}
