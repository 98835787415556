import React from 'react'
import samosaBg from '../pages/mrunali-images/about-images/samosaBg'
import taste from '../pages/mrunali-images/about-images/taste'
import image1 from '../pages/mrunali-images/about-images/image1'
import image2 from '../pages/mrunali-images/about-images/image2'
import image3 from '../pages/mrunali-images/about-images/image3'

import home1 from '../pages/mrunali-images/home1'
import home2 from '../pages/mrunali-images/home2'
import home3 from '../pages/mrunali-images/home3'
import home4 from '../pages/mrunali-images/home4'
import { Link } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';
import Caraousel from '../components/Caraousel'
import CarouselAll from '../components/CarouselAll'
import Transitions from '../components/Transitions'
import { TypeAnimation } from 'react-type-animation';


export default function About() {
  return <>
  <Transitions>
  <div className="about-bg">
  <div className="container  m-0">
    <div className="row mt-0">
    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <img src={samosaBg} alt="about"   className='bg-img-size-samosa' />
  </div>
  <div className="col-lg-12 col-md-6 col-xs-6  about m-2">
  
    <h1 className='about'>About <TypeAnimation
      sequence={[
        'Guddi Gold', // Types 'One'
        1000, // Waits 1s
        'by Sanjay Uttam . Ltd', // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        'SWAAD EK BEHTAR KAL KA', // Types 'Three' without deleting 'Two'
        () => {
          // console.log('Done typing!'); // Place optional callbacks anywhere in the array
        }
      ]}
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      style={{ fontSize: '2.5rem' }}
    />
    </h1>
    <h4 className='animate-design'>About Guddi Gold India – Popular Wheat Flour & Atta Brand :-</h4>
      </div>
      <div className="about-detail m-2">
      <p>Guddi Gold by Sanjay Uttam Agro foods pvt. Ltd. is one of the India’s agro brand that has diverse range of best quality products which includes rawa, maida, atta and suji. We understand how quality and taste matters the most to the healthy kitchens around the nation. To make your meals tastier and healthier, we present to you the finest quality of flours with natural nutrients and delightful packaged product. We provide you a healthier product with complete hygiene, love and care. We are proud that we provide the best selection of high quality, natural ingredients that are processed right, to give a right texture and a super fresh product. We ensure that our atta retains the natural dietary fibers and make your rotis softer and fluffier. We deliver you our best services with goodness of whole wheat and we promise you the pure and the best quality product in your meals. 
</p>
<div className="about-detail1">
<h3 className='about-detail1 mt-5'>WHY YOU SHOULD PREFER GUDDI GOLD? 
</h3>
<ul>
  <li className='text-dark'><img src={taste} class="rounded-full mb-0 mt-2 m-2 " height={30} alt="" /> FRESH TO MAKE IT BEST:- <p className='mt-2'>We understand how important it is for your family to provide best and fresh products. That’s why we make sure that our final product is fresh from nature and processed with utmost care and hygiene. 
</p> </li>
<li className='text-dark'><img src={image1} class="rounded-full mb-0 mt-2 m-2 " height={30} alt="" /> BEST QUALITY AND QUANTITY:- <p className='mt-2'>We ensure our best quality through choosing the finest grains, and giving you the nutritious product you deserve. We also provide more quantity with reasonable prices and healthier taste to your family. 
</p> </li>
  <li className='text-dark'> <img src={image2} class="rounded-full mb-0 mt-2 m-2 " height={30} alt="" />HYGIENIC PROCESSING: - <p className='mt-2'>We give our best product to you by prioritizing your health through hygiene maintenance. From start to the end, we make sure that there is no stone in order to ensure clean, safe and hygienic product. We take care through our continuous surveillance and modern techniques. 
</p> </li>
  <li className='text-dark'> <img src={image3} class="rounded-full mb-2 mt-0 m-2 " height={30} alt="" />PRE-EMINENT PACKAGING: - <p className='mt-2'>We give our final product through mechanized process. We believe in modernity and we use technology that gives us a complete and best packaging which ensures the freshness of the product and natural goodness. W ensure the freshness of the atta is retained to your family. 
</p> </li>
</ul>

</div>
      </div>

    </div>
  </div>
  <h1 className='about text-center'>Our Products</h1>
  <CarouselAll/>
  </div>
  </Transitions>
    </>
}
